.stripe-alert {
    position: sticky;
    width: 100%;
    padding: 5px 0;
    margin-top: 60px;
    top: 70px;
    background-color: rgba(254, 226, 226, 1);
    border: 1px solid rgba(248, 113, 113, 1);
    color: #a94442;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    column-gap: 5px;
    z-index: 10;
}

.stripe-alert a {
    color: #6a66a3;
}