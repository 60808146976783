.active-btn {
    background-color: purple;
    color: white;
    margin: 0px 3px;
    border: none;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    
}

.not-active-btn {
    background-color: #ffff;
    color: #6a66a3;
    border: none;
    margin: 0px 3px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    
}

.not-active-btn:hover {
    background-color: purple;
    color: white;
    margin: 0px 3px;
    border: none;
}

.active-btn:hover {
    background-color: purple;
    color: white;
    margin: 0px 3px;
    border: none;
}

.highlight {
    background-color: #58548C !important;
    border-radius: 5px;
    color:white;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.highlight:hover {
    background-color: #58548C !important;
    border-radius: 5px;
    color:white;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}