@import './spinner.css';
@import './cardInput.css';
@import './notificationBar.css';

.filter-cross-btn {
    padding: 1px 4px;
    border-radius: 100%;
    color: white;
    background-color: red;
}

.dropdown-content {
    /* display: none; */
    position: absolute;
    background-color: #f6f6f6;
    /* min-width: 230px; */
    overflow: auto;
    border: 1px solid #ddd;
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown a:hover {
    background-color: #ddd;
}

.dropdown {
    position: relative;
    display: block;

}