.card_input {
    border: 0;
    box-shadow: 0 0 5pt 0.5pt rgba(211, 211, 211, 0.4);
    height: 50px;
    display: block;
    width: 100%;
    margin-top: 10px;
    outline: none;
    padding: 18px 10px;
    margin-bottom: 10px;
}